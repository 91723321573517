import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: '/',
      // component: () => import('@/views/Home.vue'),
      // component: () => import('@/views/dashboard/analytics/Analytics.vue'),
      component: () => import('@/views/dashboard/index.vue'),
      meta: {
        pageTitle: 'لوحة التحكم',
        breadcrumb: [
          {
            text: 'لوحة التحكم',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
          import ('@/views/Auth/Login.vue'),
      meta: {
          layout: 'full',
      },
    },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import('@/views/Login.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/admin',
      name: 'admin',
      component: () =>
          import ('@/views/admin/index.vue'),
      meta: {
          pageTitle: 'المدراء',
          breadcrumb: [{
              text: 'المدراء',
              active: true,
          }, ],
      },
    },
    
    {
      path: '/marker',
      name: 'marker',
      component: () =>
          import ('@/views/orders/components/orderMapsMarker.vue'),
      meta: {
          pageTitle: 'المدراء',
          breadcrumb: [{
              text: 'المدراء',
              active: true,
          }, ],
      },
    },
    {
      path: '/customer',
      name: 'customer',
      component: () =>
          import ('@/views/customer/index.vue'),
      meta: {
          pageTitle: 'العملاء',
          breadcrumb: [{
              text: 'العملاء',
              active: true,
          }, ],
      },
    },

    {
      path: '/provider',
      name: 'provider',
      component: () =>
          import ('@/views/provider/index.vue'),
      meta: {
          pageTitle: 'مزودي الخدمات',
          breadcrumb: [{
              text: 'مزودي الخدمات',
              active: true,
          }, ],
      },
    },

    {
      path: '/provider-Profile/:id',
      name: 'provider-Profile',
      component: () =>
          import ('@/views/providerProfile/details.vue'),
      meta: {
        pageTitle: 'مزودي الخدمات',
        breadcrumb: [
          {
            text: 'ملف مزود الخدمة',
            active: true,
          }, 
        ],
        navActiveLink: 'providerProfile',
      },
    },

    

    {
      path: '/FileFireBase',
      name: 'FileFireBase',
      component: () =>
          import ('@/views/provider/FileFireBase.vue'),
      meta: {
          pageTitle: 'FileFireBase',
          breadcrumb: [{
              text: 'FileFireBase',
              active: true,
          }, ],
      },
    },
    
    {
      path: '/service',
      name: 'service',
      component: () =>
          import ('@/views/service/index.vue'),
      meta: {
          pageTitle: 'الخدمات',
          breadcrumb: [{
              text: 'الخدمات',
              active: true,
          }, ],
      },
    },
    {
      path: '/banner',
      name: 'banner',
      component: () =>
          import ('@/views/banner/index.vue'),
      meta: {
          pageTitle: 'البنرات اﻹعلانية',
          breadcrumb: [{
              text: 'البنرات اﻹعلانية',
              active: true,
          }, ],
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () =>
          import ('@/views/orders/index.vue'),
      meta: {
          pageTitle: 'الطلبات',
          breadcrumb: [{
              text: 'الطلبات',
              active: true,
          }, ],
      },
    },
    {
      path: '/ordersInActive',
      name: 'ordersInActive',
      component: () =>
          import ('@/views/orders/indexInActive.vue'),
      meta: {
          pageTitle: 'الطلبات',
          breadcrumb: [{
              text: 'الطلبات',
              active: true,
          }, ],
      },
    },
    {
      path: '/order/:id',
      name: 'order',
      component: () =>
          import ('@/views/orders/details.vue'),
      meta: {
        pageTitle: 'الطلبات',
        breadcrumb: [
          {
            text: 'تفاصيل الطلب',
            active: true,
          }, 
        ],
        navActiveLink: 'orders',
      },
    },
    {
      path: '/price/',
      name: 'price',
      component: () =>
          import ('@/views/price/index.vue'),
      meta: {
          pageTitle: 'اﻷسعار',
          breadcrumb: [{
              text: 'اﻷسعار',
              active: true,
          }, ],
      },
    },
    {
      path: '/timePeriod/',
      name: 'timePeriod',
      component: () =>
          import ('@/views/timePeriod/index.vue'),
      meta: {
          pageTitle: 'الفترات الزمنية',
          breadcrumb: [{
              text: 'الفترات الزمنية',
              active: true,
          }, ],
      },
    },
    

    {
      path: '/providerProfile',
      name: 'providerProfile',
      component: () =>
          import ('@/views/providerProfile/index.vue'),
      meta: {
          pageTitle: 'ملف مزودي الخدمات',
          breadcrumb: [{
              text: 'ملف مزودي الخدمات',
              active: true,
          }, ],
      },
    },

    {
      path: '/policy',
      name: 'policy',
      component: () =>
          import ('@/views/policy/index.vue'),
      meta: {
          pageTitle: 'السياسات',
          breadcrumb: [{
              text: 'السياسات',
              active: true,
          }, ],
      },
    },

    {
      path: '/policy-update/:id',
      name: 'policy-update',
      component: () =>
          import ('@/views/policy/details.vue'),
      meta: {
          pageTitle: 'السياسات',
          breadcrumb: [{
              text: 'تعديل',
              active: true,
          }, ],
          navActiveLink: 'policy',
      },
      
    },

    {
      path: '/firbase',
      name: 'firbase',
      component: () =>
          import ('@/views/firbase/sendNote.vue'),
      meta: {
          pageTitle: 'اﻹشعارات',
          breadcrumb: [{
              text: 'اﻹشعارات',
              active: true,
          }, ],
      },
      
    },
    
    
    {
      path: '/review/:id',
      name: 'review',
      component: () =>
          import ('@/views/provider/review.vue'),
      meta: {
          pageTitle: 'مزودي الخدمات',
          breadcrumb: [{
              text: 'المراجعات',
              active: true,
          }, ],
      },
    },



    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


router.beforeEach((to, from, next) => {
  if (to.meta.pageTitle) {
      document.title = ` ${process.env.VUE_APP_NAME} || ${to.meta.pageTitle}`;
  }

const token = $cookies.get(process.env.VUE_APP_NAME + '_AUTH_TOKEN')
    // If logged in, or going to the Login page.
if (token || to.name === 'login' || to.name === 'forgot-password') {
    // Continue to page.
    next()
} else {
    // Not logged in, redirect to login.
    next({ name: 'login' })
}
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
